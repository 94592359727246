import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-example',
  templateUrl: './shared-example.component.html',
  styleUrls: ['./shared-example.component.scss'],
})
export class SharedExampleComponent implements OnInit {
  ngOnInit() {}
}
