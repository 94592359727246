<div class="c-field" ion-content>
  <h6 class="c-field__label">{{ label || ('FIELDS.PASSWORD.LABEL' | translate) }}</h6>

  <div class="c-field__input-wrapper">
    <ion-input class="c-field__input c-field__input--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      [placeholder]="placeholder || ('FIELDS.PASSWORD.PLACEHOLDER' | translate)"
      [formControl]="ngControl.control" [required]="required"></ion-input>

    <ion-icon class="c-field__icon u-cursor--clickable"
      [name]="showPassword ? 'eye-off-outline' : 'eye-outline'"
      [title]="(showPassword ? 'FIELDS.PASSWORD.HIDE_PASSWORD_VISIBILITY' : 'FIELDS.PASSWORD.SHOW_PASSWORD_VISIBILITY') | translate"
      (click)="togglePasswordVisibility()"></ion-icon>
  </div>

  <app-field-error-message [visible]="showValidationErrorMessage"
    [currentControl]="ngControl.control">
  </app-field-error-message>
</div>