import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sticky-footer',
  templateUrl: './app-sticky-footer-menu.component.html',
  styleUrls: ['app-sticky-footer-menu.component.scss'],
})
export class AppStickyFooterMenuComponent {
  constructor(public router: Router) {}

  goToCheckin() {
    this.router.navigate(['/home/checkin']);
  }

  goToQuiz() {
    this.router.navigate(['/quiz']);
  }

  goToRecord() {
    this.router.navigate(['/records']);
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }
}
