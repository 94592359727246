import { EmployeeStateRepository } from '../../../modules/example-feature/state/employee/employee-state.repository';
import { Injectable } from '@angular/core';
import { EmployeeStateInterface } from '../../../modules/example-feature/state/employee/employee-state.interface';
import { SessionService } from '../../state/session/session.service';
import { User } from '../../../shared/entity/entity';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(
    private sessionService: SessionService,
    private employeeStateRepository: EmployeeStateRepository
  ) {}

  async getAuth(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      const user = this.sessionService.getCurrentUser();
      if (user) {
        resolve(user);
      } else {
        reject(null);
      }
    });
  }

  async getEmployee(): Promise<EmployeeStateInterface> {
    return new Promise(async (resolve, reject) => {
      await this.employeeStateRepository.employee$.forEach((employee) => {
        if (employee.length > 0) {
          resolve(employee[0]);
        } else {
          reject(null);
        }
      });
    });
  }
}
