import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Session } from '../../state/session/session.interface';
import { AuthResponse } from '../../../shared/dto/auth/auth.dto';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  postLogin(accessToken: AuthResponse): Observable<Session> {
    return of({ accessToken: accessToken.token, tokenType: 'Bearer' });
  }

  postLogout(): Observable<string> {
    return of('');
  }
}
