import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export function handleError(error: HttpErrorResponse): Observable<never> {
  let errorMessage = 'An error occurred\n';

  if (error.error instanceof ErrorEvent) {
    // Client-side error
    errorMessage = error.error.message;
  } else if (error.status === 422) {
    // Handle specific error status (e.g., wrong email)
    Object.keys(error.error?.errors).forEach((key) => {
      errorMessage += error.error.errors[key] + '\n';
    });
  } else if (error.message === 'HTTP_ERRORS.DEFAULT_MESSAGE') {
    const errors = JSON.parse(JSON.stringify(error)).original?.error?.errors;
    Object.keys(errors).forEach((key) => {
      errorMessage += errors[key] + '\n';
    });
  } else {
    // Server-side error
    errorMessage = `Server Error: ${error.status}, Message: ${error.message}`;
  }

  console.error(errorMessage);
  return throwError(errorMessage);
}
