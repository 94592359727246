<div class="menu-footer">
    <ion-tab-bar >
        <ion-tab-button tab="home" (click)="goToCheckin()" tappable="">
            <ion-icon name="home-outline"></ion-icon>
            <ion-label>Home</ion-label>
        </ion-tab-button>
      <ion-tab-button tab="quiz" (click)="goToQuiz()" tappable="">
        <ion-icon name="rocket-outline"></ion-icon>
        <ion-label>Quiz</ion-label>
      </ion-tab-button>
        <ion-tab-button tab="record" (click)="goToRecord()" tappable>
            <ion-icon name="time-outline"></ion-icon>
            <ion-label>My record</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="profile">
            <ion-icon name="person-outline" (click)="goToProfile()"></ion-icon>
            <ion-label>Profile</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</div>
