import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

/*
  Please check the article below for understanding how to structure modules
  https://medium.com/swlh/angular-organizing-features-and-modules-e582611a720e
*/

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./modules/example-feature/example-feature.module').then((m) => m.HomePageModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./modules/example-feature/pages/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: 'camera',
    loadChildren: () =>
      import('./modules/example-feature/pages/selfie-camera/selfie-camera.module').then(
        (m) => m.SelfieCameraModule
      ),
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/example-feature/pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/example-feature/pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'records',
    loadChildren: () =>
      import('./modules/example-feature/pages/records/records.module').then((m) => m.RecordsModule),
  },
  {
    path: 'quiz',
    loadChildren: () => import('./modules/example-feature/pages/quiz/quiz.module').then((m) => m.QuizModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
