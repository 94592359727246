import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

import { Platform } from '@ionic/angular';

import { TranslationsService } from './core/services/translations-service/translations.service';
import { NavigationEnd, Router } from '@angular/router';
import { EventService } from './core/services/events/event.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public currentPage = '';

  constructor(
    private translationsService: TranslationsService,
    private platform: Platform,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private eventService: EventService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Get the current route
        const currentRoute = this.getCurrentRoute();
        this.currentPage = currentRoute;
        console.log('Current Route:', currentRoute);
        this.updateCSS();
      }
    });
    this.initializeApp();
    this.listen();
  }

  ionViewDidEnter(): void {}

  updateCSS() {
    setTimeout(() => {
      const el1 = this.el.nativeElement.querySelectorAll('.menu-footer'); // Ambil elemen menu-footer menggunakan ElementRef
      const pageContentElements = ['app-collections', 'app-quiz-result-list', 'app-quiz-page'];
      const el2: any = [];
      pageContentElements.forEach((str) => {
        el2.push(this.el.nativeElement.querySelectorAll(str)); // Ambil elemen page-content menggunakan ElementRef
      });
      if (el1) {
        el1.forEach((footer: HTMLElement) => {
          el2.forEach((pages: any) => {
            try {
              pages.forEach((page: any) => {
                this.renderer.setStyle(page, 'padding-bottom', footer.offsetHeight + 'px');
              });
            } catch (e) {
              /**empty**/
            }
          });
        });
      }
    }, 300);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translationsService.init();
      this.setMobileStarterAssets();
    });
  }

  listen() {
    this.eventService.subscribe('loadCSS', async () => {
      await this.updateCSS();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async setMobileStarterAssets() {}

  ngOnDestroy() {
    this.eventService.unsubscribe('loadCSS');
  }

  private getCurrentRoute(): string {
    let route = this.router.routerState.snapshot.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route?.routeConfig?.component?.name ?? '';
  }
}
