import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorageService } from '../../../../core/storage/auth/auth-storage.service';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './app-sticky-header.component.html',
  styleUrls: ['./app-sticky-header.component.scss'],
})
export class StickyHeaderComponent implements OnInit {
  username: string = 'unknown';

  avatar: any = null;

  constructor(
    private router: Router,
    private authStorageService: AuthStorageService
  ) {}

  ngOnInit() {
    // Assume you have a service to get the logged-in user's details
    // You would inject the service and use it to set the username
    this.username = 'User'; // Replace with actual username

    this.authStorageService.getEmployee().then((employee) => {
      this.username = employee.fullName;
      this.avatar = employee.user?.photo;
    });
  }

  goToNotifications() {
    this.router.navigate(['/notifications']);
  }
}
