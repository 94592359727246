import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { selectAllEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { Employee } from '../../../../shared/entity/entity';
import { EmployeeStateInterface } from './employee-state.interface';
import { StoragePreferenceEnum } from '../../../../core/storage/constant/storage.constant';

export interface EmployeeState {
  metaInformation: boolean;
}

const initialState: EmployeeState = {
  metaInformation: false,
};

const store = createStore(
  {
    name: StoragePreferenceEnum.Employee,
  },
  withProps<EmployeeState>(initialState),
  withEntities<EmployeeStateInterface>()
);

export const persist = persistState(store, {
  key: StoragePreferenceEnum.Employee,
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class EmployeeStateRepository {
  employee$ = store.pipe(selectAllEntities());

  upsert(entity: Employee) {
    store.update(upsertEntities(entity));
  }

  removeItems() {
    store.reset();
  }
}
