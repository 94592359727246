// employee.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Employee } from '../entity/entity';
import { CreateEmployeeDTO } from '../dto/employee/create-employee.dto';
import { UpdateEmployeeDTO } from '../dto/employee/update-employee.dto';
import { catchError } from 'rxjs/operators';
import { handleError } from '../helpers/error.helper';
import { environment } from '../../../environments/environment';

// filter-employee.dto.ts

export interface InfinityPaginationResultType<T> {
  data: T[];
  hasNextPage: boolean;
}

export interface FilterEmployeeDto {
  fullName?: string;
  department?: string;
  position?: string;
  birthDate?: Date;
  birthPlace?: string;
  userId?: number;
}

// sort-employee.dto.ts

export interface SortEmployeeDto {
  orderBy: keyof Employee;
  order: string;
}

// query-employee.dto.ts

export interface QueryEmployeeDTO {
  page?: number;
  limit?: number;
  filters?: FilterEmployeeDto | null;
  sort?: SortEmployeeDto[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private apiUrl = environment.api.endpoint; // Replace with your NestJS API URL

  constructor(private http: HttpClient) {}

  getAllEmployees(query: QueryEmployeeDTO): Observable<InfinityPaginationResultType<Employee>> {
    let params = new HttpParams()
      .set('page', query.page ? query.page.toString() : '1')
      .set('limit', query.limit ? query.limit.toString() : '10');

    if (query.filters) {
      params = params.append('filters', JSON.stringify(query.filters));
    }

    if (query.sort) {
      params = params.append('sort', JSON.stringify(query.sort));
    }

    return this.http
      .get<InfinityPaginationResultType<Employee>>(`${this.apiUrl}employee`, { params })
      .pipe(catchError(handleError));
  }

  getEmployeeById(id: number): Observable<Employee> {
    return this.http.get<Employee>(`${this.apiUrl}employee/${id}`).pipe(catchError(handleError));
  }

  createEmployee(createEmployeeDto: CreateEmployeeDTO): Observable<Employee> {
    return this.http.post<Employee>(`${this.apiUrl}employee`, createEmployeeDto).pipe(catchError(handleError));
  }

  updateEmployee(id: number, updateEmployeeDto: UpdateEmployeeDTO): Observable<Employee> {
    return this.http
      .patch<Employee>(`${this.apiUrl}employee/${id}`, updateEmployeeDto)
      .pipe(catchError(handleError));
  }

  deleteEmployee(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}employee/${id}`).pipe(catchError(handleError));
  }
}
