import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionApiService } from '../../api/session/session-api.service';
import { SessionRepository } from './session.repository';
import { AuthResponse } from '../../../shared/dto/auth/auth.dto';
import { EmployeeService } from '../../../shared/services/employee.service';
import { EmployeeStateService } from '../../../modules/example-feature/state/employee/employee-state.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private sessionRepository: SessionRepository,
    private sessionApiService: SessionApiService,
    private employeeService: EmployeeService,
    private employeeStateService: EmployeeStateService
  ) {}

  login(accessToken: AuthResponse) {
    console.log('login', accessToken);
    return this.sessionApiService.postLogin(accessToken).pipe(
      tap((session) => {
        this.sessionRepository.update({ session });
        this.sessionRepository.updateUser({
          currentUser: {
            id: accessToken.user.id,
            email: accessToken.user.email,
            role: accessToken.user.role,
            firstName: accessToken.user.firstName,
            lastName: accessToken.user.lastName,
            createdAt: accessToken.user.createdAt,
            deletedAt: accessToken.user.deletedAt,
            photo: accessToken.user.photo,
            status: accessToken.user.status,
            provider: accessToken.user.provider,
            socialId: accessToken.user.socialId,
            updatedAt: accessToken.user.updatedAt,
          },
        });

        // Save employee information to employee state
        this.employeeService
          .getAllEmployees({
            filters: {
              userId: accessToken.user.id,
            },
          })
          .subscribe((result) => {
            if (result?.data?.length > 0) {
              this.employeeStateService.reset();
              this.employeeStateService
                .update({
                  id: accessToken.user.id,
                  ...result.data[0],
                })
                .subscribe(() => {});
            }
          });
      })
    );
  }

  logout() {
    return this.sessionApiService.postLogout().pipe(
      tap(() => {
        this.sessionRepository.update({ session: undefined });
        this.sessionRepository.updateUser({ currentUser: undefined });
      })
    );
  }

  getCurrentUser() {
    return this.sessionRepository.currentUser();
  }
}
