import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmployeeStateInterface } from './employee-state.interface';
import { EmployeeStateRepository } from './employee-state.repository';

@Injectable({
  providedIn: 'root',
})
export class EmployeeStateService {
  constructor(private employeeStateRepository: EmployeeStateRepository) {}

  update(employee: EmployeeStateInterface) {
    return of(employee).pipe(
      tap((response) => {
        // Upsert it in store
        this.employeeStateRepository.upsert(response);
      })
    );
  }

  reset() {
    this.employeeStateRepository.removeItems();
  }
}
