import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { enableElfProdMode } from '@ngneat/elf';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
// import { bootstrapApplication } from '@angular/platform-browser';
// import { AppComponent } from './app/app.component';

// bootstrapApplication(AppComponent, {
//   providers: [provideCharts(withDefaultRegisterables())],
// }).catch((err) => console.error(err));

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
