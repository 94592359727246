<div class="c-field" ion-content>
  <h6 class="c-field__label">{{ label }}</h6>

  <div class="c-field__file">
    <input [id]="ngControl.name + '-file'" type="file" (change)="setValue($event.target.files)" />

    <label [for]="ngControl.name + '-file'" appDnd (fileDropped)="setValue($event)">
      <p>{{ value ? value['name'] : (placeholder || 'FIELDS.FILE.PLACEHOLDER' | translate) }}</p>
    </label>
  </div>
</div>
