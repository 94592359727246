<app-modal>
  <h2 class="c-modal__title">Modal Title</h2>

  <p class="c-modal__description">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
    recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
    provident expedita ipsa accusantium corrupti sapiente!
  </p>

  <div *ngIf="longText">
    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>

    <p class="c-modal__description">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam incidunt similique minus magnam,
      recusandae maxime blanditiis est, vel repellendus inventore, quaerat nobis. Maxime placeat
      provident expedita ipsa accusantium corrupti sapiente!
    </p>
  </div>

  <div class="c-modal__footer">
    <app-button outline="true" inverted="true" (click)="dismiss();">
      Close
    </app-button>
  </div>
</app-modal>